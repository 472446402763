import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box
} from "@mui/material";
import {
  Link
} from "react-router-dom"
import MenuIcon from "@mui/icons-material/Menu";


const pages = ["Home", "Chromacells", "Mint", "About"];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const drawerStateChange = () => {
    setOpenDrawer(!openDrawer);
  }

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
            sx: {
                background: "#000000",
                paddingTop: "6%",
                
            }
        }}
      >
        <List >
          {pages.map((page, index) => (
            <ListItemButton onClick={drawerStateChange} key={index} component={Link} to={page === "Home" ? "/" : "/" + page.toLowerCase()}>
              <ListItemIcon>
                <ListItemText primaryTypographyProps={{color: "white", fontFamily: "Garamond", fontStyle: "bold", fontSize: "1.4rem"}}>{page}</ListItemText>
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Drawer>
      <Box>
      <IconButton
        sx={{ color: "white",   pt: '28px', ml: '16px',}}
        onClick={drawerStateChange}
      >
        <MenuIcon/>
      </IconButton>
      </Box>
    </React.Fragment>
  );
};

export default DrawerComp;