// @ts-nocheck
import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import * as THREE from 'three';
import palettes from "nice-color-palettes";
import { random } from "lodash";
import { BoxGeometry, Color, ConeGeometry,  } from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { RoundedBoxGeometry } from 'three/examples/jsm/geometries/RoundedBoxGeometry.js';
import { FlakesTexture } from 'three/addons/textures/FlakesTexture.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry'
import { MeshBasicMaterial } from "three";
import { time } from "console";
import { Box } from "@mui/material";
//import SceneInit from '../lib/SceneInit';

// Globals
const palette = pickRandom(palettes);
const scene = new THREE.Scene();
scene.background = new THREE.Color("#F8F8FF")
// #F2F7FD Cotton
// #FFFEFC Porcelain 


const camera = new THREE.PerspectiveCamera(
  100,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
const light = new THREE.DirectionalLight("#F4E99B", 1);
const ambientLight = new THREE.AmbientLight("hsl(0, 0%, 95%)");
light.position.set(0, 4, 4);

scene.fog = new THREE.Fog( 0x050505, 2000, 3500 );

//

scene.add( new THREE.AmbientLight( 0x444444 ) );

const light1 = new THREE.DirectionalLight( 0xffffff, 1.5 );
light1.position.set( 1, 1, 1 );
scene.add( light1 );

const light2 = new THREE.DirectionalLight( 0xF4E99B, 1.5 );
light2.position.set( 0, - 1, 0 );
scene.add( light2 );

// font loader
const loader = new FontLoader();
// texture loader
const textureLoader = new THREE.TextureLoader();

const r = 300;

function createGeometry() {

  const geometry = new THREE.BufferGeometry();
  const vertices = [];

  const vertex = new THREE.Vector3();

  for ( let i = 0; i < 1500; i ++ ) {

    vertex.x = Math.random() * 2 - 1;
    vertex.y = Math.random() * 2 - 1;
    vertex.z = Math.random() * 2 - 1;
    vertex.normalize();
    vertex.multiplyScalar( r );

    vertices.push( vertex.x, vertex.y, vertex.z );

    vertex.multiplyScalar( Math.random() * 0.09 + 1 );

    vertices.push( vertex.x, vertex.y, vertex.z );

  }

  geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( vertices, 3 ) );

  return geometry;

}

const parameters = [[ 0.25, 0xbd4378  , 1 ], [ 0.75, 0x00e676, 1 ], [ 0.75, 0x33eb91, 0.75 ], [ 1, 0x1c54b2, 1.0 ], [ 1.25, 0x2979ff, 1.0 ],
					[ 9.0, 0xab003c, 1.0 ], [ 10.5, 0xf50057, 1.0 ], [ 4.5, 0xffffff, 0.25 ], [ 5.5, 0xffffff, 0.125 ]];

const geometry = createGeometry();

for ( let i = 0; i < parameters.length; ++ i ) {

  const p = parameters[ i ];

  const material = new THREE.LineBasicMaterial( { color: p[ 1 ] , opacity: p[ 2 ], linewidth: 4 } );

  const line = new THREE.LineSegments( geometry, material );
		line.scale.x = line.scale.y = line.scale.z = p[ 0 ];
		line.userData.originalScale = p[ 0 ];
		line.rotation.y = Math.random() * Math.PI;
		line.updateMatrix();
		scene.add( line );
}

// Cones
const coneGeometry = new THREE.ConeGeometry(10, 14, 3)
coneGeometry.clearGroups();
coneGeometry.addGroup( 0, Infinity, 0 );
coneGeometry.addGroup( 0, Infinity, 1 );

// Cubes
const cubeGeometry = new THREE.BoxGeometry(10, 10, 10);
cubeGeometry.clearGroups();
cubeGeometry.addGroup( 0, Infinity, 0 );
cubeGeometry.addGroup( 0, Infinity, 1 );

// custom geometry
const roundedGeometry = new RoundedBoxGeometry(10, 10, 10, 2, 2, 2)
roundedGeometry.clearGroups();
roundedGeometry.addGroup( 0, Infinity, 0 );
roundedGeometry.addGroup( 0, Infinity, 1 );

// create 2 materials

//const loader3 = new THREE.ImageBitmapLoader();

const material1 = new THREE.MeshBasicMaterial({
  map: textureLoader.load('hedera-symbol.png'),
  transparent: true,
});

/*
const material1 = new THREE.MeshBasicMaterial({
  map: textureLoader.load('hedera-symbol.png'),
  transparent: true,
});
*/



// apply 2 materials to geometry


var cubes = [];

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

const normalMap3 = new THREE.CanvasTexture( new FlakesTexture() );
							normalMap3.wrapS = THREE.RepeatWrapping;
							normalMap3.wrapT = THREE.RepeatWrapping;
							normalMap3.repeat.x = 10;
							normalMap3.repeat.y = 6;
							normalMap3.anisotropy = 16;


for (let i = 0; i < 50; i++) {


  let material = new THREE.MeshPhysicalMaterial( {
    clearcoat: 1.0,
    clearcoatRoughness: 0.1,
    metalness: 0.9,
    roughness: 0.5,
    color: pickRandom(palette),
    normalMap: normalMap3,
    normalScale: new THREE.Vector2( 0.15, 0.15 )
  } );

  const material0 = new THREE.MeshPhongMaterial({
    color: pickRandom(palette), specular: pickRandom(palette), shininess: 2,
					side: THREE.DoubleSide, vertexColors: true
  });
  const materials = [material, material1];



  //console.log(geometry)
  const cubeMesh = new THREE.Mesh(
       roundedGeometry,
       materials
  );
  cubeMesh.scale.set(
    random(0, 1.001, true),
    random(1, 1.001, true),
    random(0, 1.001, true)
  );
  cubeMesh.position.set(
    random(-47, 47, true),
    random(-42, 42, true),
    random(-30, 30, true)
  );
  scene.add(cubeMesh);
  cubes.push(cubeMesh);
}



/*
var geometry: TextGeometry = loader.load( 'helvetiker_regular.typeface.json', function ( font ) {
    console.log("Loaded the font")
    geometry = new TextGeometry( "h", {
            font: font,
            size: 12,
            height: 12,
        } );
        for (let i = 0; i < 20; i++) {
            //console.log(geometry)
            const cubeMesh = new THREE.Mesh(
                 geometry,
              new THREE.MeshStandardMaterial({
                color: new Color("#000000")
              })
            );
            cubeMesh.scale.set(
              random(-1.01, 1.01, true),
              random(-1.01, 1.01, true),
              random(-1.01, 1.01, true)
            );
            cubeMesh.position.set(
              random(-45, 45, true),
              random(-45, 45, true),
              random(-45, 45, true)
            );
            scene.add(cubeMesh);
            cubes.push(cubeMesh);
          }
    } );

    */ 

    const renderer = new THREE.WebGLRenderer({
      antialias: true,

    });

    var controls = new OrbitControls( camera, renderer.domElement );
    controls.autoRotate = true;
    controls.minZoom = 0.0
    controls.maxZoom = 0.0
    controls.maxDistance = 200.0
    controls.minDistance = 50.0
    controls.autoRotateSpeed = 1;

    function animate() {

      
        
        requestAnimationFrame(animate);
        
        cubes.forEach(cube => {
          //console.log(cube.rotation.x)
          
          var time = Date.now() * 0.000000000001
          
          cube.rotation.x += random(0.005, 0.017, true);
          cube.rotation.y += random(0.005, 0.017, true);
          
        });
        
        renderer.setSize(sizes.width, sizes.height);
        renderer.render(scene, camera);

        const time = Date.now() * 0.00005;

				for ( let i = 0; i < scene.children.length; i ++ ) {

					const object = scene.children[ i ];

					if ( object.isLine ) {

						object.rotation.y = time * ( i < 4 ? ( i + 1 ) : - ( i + 1 ) );

						if ( i < 5 ) {

							const scale = object.userData.originalScale * ( i / 6 + 1 ) * ( 1 + 0.5 * Math.sin( 7 * time ) );

							object.scale.x = object.scale.y = object.scale.z = scale;

						}

					}

				}


        update()
      }

    //renderer.setSize(sizes.width, sizes.height);

    animate();

    function update() {
	      controls.update();
	      
    }



const Home = () => {

  const containerRef = useRef(null);

  useEffect(() => {

    //responsive canvas
    if (containerRef.current !== null) {

      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor("white", 1);
      containerRef.current.appendChild(renderer.domElement);

      scene.add(camera);
      scene.add(light);
      scene.add(ambientLight);

      camera.position.set(40, 40, 40);
      camera.lookAt(new THREE.Vector3());

      window.addEventListener('resize', () => {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;
    
        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();
    
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        
        //renderer.render(scene, camera); // -> Also needed
      });

    }
  }, [containerRef]);

  return (
    <React.Fragment>
      
        <div  ref={containerRef} style={{flex: 'auto', overflow: 'hidden'}} />
        
    </React.Fragment>
  )
}

//const rootElement = document.getElementById("root");
//ReactDOM.render(<Home />, rootElement);

// Utils
function pickRandom(array) {
  return array[random(0, array.length - 1)];
}

export default Home;

//  return <div className="App" ref={containerRef} style={{flex: 'auto', overflow: "hidden"}} />;

/*

    if (cubes) {
        cubes = cubes.forEach(cube => {
            console.log("Activation")
            console.log(cube.rotation.x)
            cube.rotation.x = 0.0;
            cube.rotation.y = 0.0;
    
        })
      }
 */
/*

// Globals
const palette = pickRandom(palettes);
const scene = new THREE.Scene();
scene.background = new THREE.Color("#ffffff")
//const canvas = document.querySelector('.webgl');
//console.log(canvas)

const camera = new THREE.PerspectiveCamera(
  100,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);
const light = new THREE.DirectionalLight("white", 1);
const ambientLight = new THREE.AmbientLight("hsl(0, 0%, 95%)");
light.position.set(0, 4, 4);

// font loader



// Cubes
const cubeGeometry = new THREE.BoxGeometry(1, 1, 1);

var cubes = [];

const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}
const loader = new FontLoader();

var geometry: TextGeometry = loader.load( 'helvetiker_regular.typeface.json', function ( font ) {
    console.log("Loaded the font")
    geometry = new TextGeometry( 'H', {
            font: font,
            size: 15,
            height: 4,
        } );
        for (let i = 0; i < 3; i++) {
            //console.log(geometry)
            const cubeMesh = new THREE.Mesh(
                 geometry,
              new THREE.MeshStandardMaterial({
                color: new Color("#000000")
              })
            );
            cubeMesh.scale.set(
              random(-1.1, 1.1, true),
              random(-1.1, 1.1, true),
              random(-1.1, 1.1, true)
            );
            cubeMesh.position.set(
              random(-30, 30, true),
              random(-30, 30, true),
              random(-30, 30, true)
            );
            scene.add(cubeMesh);
            cubes.push(cubeMesh);
          }
    } );

    

const Home = () => {

    const containerRef = useRef(null);

    const renderer = new THREE.WebGLRenderer({
        antialias: true,
    });

    

    function animate() {
        
        requestAnimationFrame(animate);
        
        cubes.forEach(cube => {
          //console.log(cube.rotation.x)
          
          var time = Date.now() * 0.00000000000001
          
          cube.rotation.x += time;
          cube.rotation.y += time;
          
          
        });
        
        renderer.setSize(sizes.width, sizes.height);
        renderer.render(scene, camera);
      }

    //renderer.setSize(sizes.width, sizes.height);

  useEffect(() => {

    //responsive canvas
    if (containerRef.current !== null) {

      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor("white", 1);
      containerRef.current.appendChild(renderer.domElement);

      scene.add(camera);
      scene.add(light);
      scene.add(ambientLight);

      camera.position.set(40, 40, 40);
      camera.lookAt(new THREE.Vector3());

      
      

      // Generate some cubes
      animate();

      

    window.addEventListener('resize', () => {
        sizes.width = window.innerWidth;
        sizes.height = window.innerHeight;
    
        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();
    
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        
        //renderer.render(scene, camera); // -> Also needed
    });

      // Declarations
      
    
    }
  }, [containerRef]);

  return (
    <React.Fragment>
        <div className="App" ref={containerRef} style={{flex: 'auto'}} />
    </React.Fragment>
  )
}

//const rootElement = document.getElementById("root");
//ReactDOM.render(<Home />, rootElement);

// Utils
function pickRandom(array) {
  return array[random(0, array.length - 1)];
}

export default Home;
*/