import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    useTheme,
    Box,
    Stack,
    useMediaQuery, 
    Link, Button, TextField, IconButton, DialogTitle,
    Dialog
  } from "@mui/material";
import MintService from '../service/MintService';
import {Link as Link2} from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import thankyou from '../assets/thankyou.gif';
import HashconnectService from '../service/hashconnectService';
import axios from 'axios';
import copy from 'clipboard-copy';
import sample_21 from '../assets/sample_21.png';
import shiba_inu from '../assets/dog-smile.gif'


const Img = styled('img')({
    maxWidth: '100%',
    maxHeight: '100%',
})
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
    
  },
  '& .MuiDialogTitle-root': {
    fontFamily: 'ProximaNovaBold',
    backgroundColor: '#F8F8FF',
    fontSize: '1.3rem'
  },
}));


var hashConnect: HashconnectService = new HashconnectService()
declare const window: any;


/*
const initiateHashconnect = async () => {

  await hashConnect.initHashconnect()
  //console.log("VIbe Check")
}
initiateHashconnect()
*/

//console.log(hashConnect.pairingData?.topic)

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const MintPage = () => {

  /*
    const [states, changeState] = useState([hashConnect.state.toString()])
    console.log(states[0])
    const [pairingString, changeString] = useState('Pairing String')
    */
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };


    const theme = useTheme();
    //console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const isMatchSmall = useMediaQuery(theme.breakpoints.down("sm"));

    // TESTING SITE
    //console.log(pairingString)
    //initiateHashconnect()

    const showPairingString = () => {
      //console.log(hashConnect.pairingString)
      //changeString(hashConnect.pairingString)
    }

    const connectionListener = () => {
      hashConnect.hashconnect.pairingEvent.once((pairingData) => {
        //console.log(hashConnect.state)
        if (hashConnect.state === 'Connected' || hashConnect.state === 'Paired') {
          //changeState([hashConnect.state])
          //changeString('Pairing String')
          window.localStorage.setItem("pairedWallet", hashConnect.pairingData?.accountIds[0])
        }
      })
    }

    const copyClipboard = () => {
      //copy(pairingString)
      connectionListener()
    }  

    setTimeout(() => {
      //console.log('WHAT')
      //showPairingString()
      
    }, 1500)

    /*
    hashConnect.hashconnect.connectionStatusChangeEvent.once((state) => {
      const walletCheck = window.localStorage.getItem('pairedWallet')
      console.log("Changing state to..." + state)
      if (walletCheck) {
        //changeState([state])
        //connectionStatus = "Connected"
        //checkAssociationStatus()
      } else {
        //changeState(['Disconnected'])
        //connectionStatus = "Disconnected"
      }
      if (state === 'Paired') {
        //changeString("Pairing String")
        //connectionStatus = "Paired"
        //checkAssociationStatus()
      }
    })
    */

    /*
    hashConnect.hashconnect.acknowledgeMessageEvent.once((acknowledgeData) => {
      //console.log('Pizdarija')
      console.log(+ acknowledgeData)
    })   */

    const disconnectWallet = async () => {
      hashConnect.disconnect(hashConnect.topic);
      hashConnect.clearPairings();
      //changeState([hashConnect.state])
     //console.log(hashConnect.state)
      window.localStorage.removeItem("pairedWallet")
      //changeString('Pairing String')
    }
    

    const connectWithBrowserExtension = async () => {

      console.log(hashConnect.topic)
      const walletCheck = window.localStorage.getItem('pairedWallet')
      console.log(walletCheck)
   
      if (hashConnect.state === 'Paired') {
        disconnectWallet()
        
      }else if (walletCheck) {
        
        disconnectWallet()
      } else if (hashConnect.state === "Disconnected") {
        //await initiateHashconnect()

        hashConnect.connectToExtension()
        connectionListener()
      } else {
        hashConnect.connectToExtension()
        connectionListener()
      }
    }


  
  const getAssociateTransBytes = async (accountId: string | undefined) => {
      if (accountId !== undefined) {
          const transRequest = await axios.get("https://barpunks.com/associateTransaction/" + accountId, {responseType: "arraybuffer"})
          const signTx = Uint8Array.from(Buffer.from(transRequest.data, "binary"))
          const signTx2 = Buffer.from(transRequest.data, "binary")
          //console.log(await signTx)
          //console.log(await signTx2)
          return await signTx
      } else {
          //TODO: Error message
      }
  }

  const getSwapTransBytes = async (accountId: string) => {
      if (accountId !== undefined) {
          
          const transRequest = await axios.get("https://barpunks.com/mintRequest3/" + accountId, {responseType: "arraybuffer"})
          //console.log(transRequest.data)
          const signTx = Uint8Array.from(Buffer.from(transRequest.data, "binary"))
          //console.log(signTx)
          if (signTx.length > 5) {
              return await signTx
          } else {
              return null
          }
      } else {
          //TODO: Error message
      }
  }

  const associateTransaction = async () => {
      if (hashConnect.state === "Paired" || hashConnect.state === "Connected") {

          const accountId = window.localStorage.getItem("pairedWallet")
          console.log(accountId)
          
          if (accountId !== null || accountId !== undefined) {
              let transactionBytes = await getAssociateTransBytes(accountId)
  
              await hashConnect.sendTransaction(transactionBytes!, accountId)
          }
          
      }
  }

  /*
  const mintTransaction = async () => {
      updateState(true)
      setTimeout(() => {
          updateState(false)
      }, 6000)

      if (hashConnect.status === "Paired") {
          var data = JSON.parse(localStorage.getItem("hashconnectData"))
          accountId = window.localStorage.getItem("activeWallet")

          if (accountId !== undefined) {
              let transactionBytes = await getSwapTransBytes(accountId)

              if (transactionBytes) {
                  await hashConnect.sendTransaction(transactionBytes, accountId)
              } else {
                  // TODO: Error message
              }
          }
      }
  }
  */

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: "#F8F8FF"}}>
            <Stack 
              direction='column'
              justifyContent= 'center'
              alignItems='center'

              >
                <Box
                  
                  maxWidth={900}
                  my={4}
                  
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '96%'
                  }}>
                    <Typography variant='h3' align='left' mx='auto' sx={{fontFamily: 'CocotteAlternate'}}>
                      Chromacells | Generative Work That Attempts to Simulate Real Tissue
                    </Typography>
                  </Box>
                
                <Stack 
                direction={isMatch?"column":"row"}
                justifyContent= 'center'
                alignItems='center'
                
                spacing={1}
                width="96%"
                >
                  
                    
                <Box
                  
                  maxWidth={580}
                  mt='auto'
                  mb={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '100%'
                  }}>
                      <Box
                    
                    maxWidth={580}
                    mb='auto'
                    
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: '100%'
                    }}>
                      <Typography mb={isMatch?2:1} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                        <b style={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>Chromacells 1st Edition</b> are 222 programmatically generated patterns produced by an algorithm that is capable of immense variety. Moreover the color palette is randomly generated essentially making each artwork extremely special.
                         For this reason is the prefix of the collection name <b style={{fontFamily: 'ProximaNovaItalic'}}>Chroma</b> which means <b style={{fontFamily: 'ProximaNovaItalic'}}>purity or intensity of colour</b>. 
                        
                      </Typography>
                      <Typography mb={isMatch?2:1} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      By acquiring the NFT you get a digital proof of ownership, immutably stored on the Hedera Network. Every NFT minted here is also carbon negative.
                      </Typography>
                      <Typography mb={isMatch?2:3.6} mr='auto' sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      To make sure you don't miss out on the mint date make sure you follow me on <Link
                      href='https://twitter.com/nigelthecreator'
                      color='inherit'
                      target="_blank"
                      rel="noopener"
                      sx={{textStyle: 'bold', textWeight: 800, fontFamily: 'ProximaNovaBold'}}
                      >Twitter</Link>. 
                      </Typography>
                      
                    </Box>

                    
                    <Box
                    mb={1}
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    ml={2}
                    
                    padding={1.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 220,
                      backgroundColor: 'black',
                      color: 'white',

                    }}>
                      <Typography align='left' mr='auto'  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>
                        Ongoing Mint
                    </Typography>
                    
                    </Box>
                    <Box
                    mb={1}
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    ml={2}
                    
                    padding={1.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 220,
                      backgroundColor: 'black',
                      color: 'white',

                    }}>
                      <Typography align='left' mr='auto'   sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>
                        1st Edition Supply: 222
                    </Typography>
                    </Box>
                    <Box
                    
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    ml={2}
                    
                    padding={1.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 220,
                      backgroundColor: 'black',
                      color: 'white',

                    }}>
                      <Typography align='left' mr='auto'  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>
                        Price: 1000 ℏ
                    </Typography>
                    </Box>
                    
                  </Box>
                  <Box   
                    mt={4}
                    borderRadius={4}
                    border={6}
                      maxWidth={620}
                      component='img'
                      src={sample_21}
                      sx={{
                        width: '96%',
                                                             
                      }}
                      >
                      </Box>

                </Stack>
                <Box
                maxWidth={600}
                border={4}
                borderRadius={4}
                borderColor="#69f0ae"
                mt={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'left',
                  width: '96%',
                  backgroundColor: '#69f0ae'
                }}>
                
                <Typography mx={5} sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.4rem'}}>
                  For each minted Chromacell you will also be eligible to mint 1 Mycelium for free with the added benefit of acquiring WL spots for subsequent collections.
                </Typography>
                </Box>
                <MintService name='Chromacells' id={3} collectionId={"0.0.1391422"} activated={true}></MintService>
                  <Box
                  
                  height={100}
                  mx={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    
                  }}>
                    <Typography mb='auto' sx={{fontFamily: 'Arial', fontSize: '12px'}}>
                      By Clicking "MINT" Button You Agree With The <Link
                      component={Link2}
                      to='/termsandconditions'
                      color='inherit'
                      target="_blank"
                      
                      >Terms and Conditions</Link>
                    </Typography>
                  </Box>

                  <Button component={Link2} to="/launchpad" variant='contained' size='large' sx={{borderRadius: 4, fontSize: 18, fontFamily: "ProximaBold", fontWeight: 800, textDecoration: 'none', '&:hover': {
                        boxShadow: 5,
                        backgroundColor: '#0d47a1'
                     }}}>
                    BACK TO LAUNCHPAD
                  </Button>
              </Stack>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

        </Box>
    )
}

export default MintPage;

/*   NEVER DELETE THIS!!!!

<Button onClick={associateTransaction} variant='contained' size='large' sx={{marginTop: 2, borderRadius: 4, fontSize: 18, fontFamily: "ProximaBold", fontWeight: 800, backgroundColor: '#6d1b7b'}}>
                          ASSOCIATE TOKEN
                        </Button>
                        <Typography mb={2} mt={0.5} sx={{fontFamily: 'ProximaNovaItalic', fontSize: '0.95rem'}}>
                          (The process of associating tokens is needed only once)
                          </Typography> 
                        <Button onClick={handleClickOpen} variant='contained' size='large' sx={{marginTop: 2, marginBottom: 4, borderRadius: 4, fontSize: 18, fontFamily: "ProximaBold", fontWeight: 800, backgroundColor: '#6d1b7b'}}>
                        MINT
                        </Button>
                        <BootstrapDialog
                          
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                          
                        >
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                            1. Sign The Transaction on HashPack App &#9989; 
                          </BootstrapDialogTitle>
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            2. Check NFTs Tab On HashPack To See What You've Minted &#9989;
                          </BootstrapDialogTitle>
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Almost forgot...thank you for minting &#128591; &#128147;
                          </BootstrapDialogTitle>
                          <Box
                            border={8}
                            borderRadius={4}
                            
                            mx='auto'
                            my={2}
                            maxWidth={500}
                            component='img'
                            src={thankyou}
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                              width: '100%',                                 
                            }}
                          >
                          </Box>
                            
                          
                        </BootstrapDialog>

*/