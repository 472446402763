import './App.css';
import Header from './components/header';
import Footer from './components/Footer';

function App() {

  return (
    <div className="App" style={{overflow: 'hidden'}}>
      <Header></Header>
      <Footer></Footer>
    </div>
  );
}

export default App;

/*

import {Link, BrowserRouter, Routes, Route} from 'react-router-dom'
import {
  useMediaQuery,
  useTheme,
  BottomNavigation,
  BottomNavigationAction
} from "@mui/material";
import { fontFamily } from '@mui/system';
import Home from "./views/Home";
import ChromacytosInfo from "./views/ChromacytosInfo";
import MintPage from "./views/MintPage";
import About from "./views/About";

{isMatch ? (
            <>
              <BrowserRouter>
                <BottomNavigation
                  showLabels
                  value={value}
                  onChange={(event, newValue) => {
                  setValue(newValue);
                    }}
                                        
                  sx={{
                    "& .MuiBottomNavigationAction-root": {
                    color: "#000000",
                    fontFamily: "Garamond",
                    fontSize: "1.3rem",
                    fontStyle: 'bold',
                    fontWeight: 800
                      }
                      }}
                  >
                      <BottomNavigationAction label="Home" component={Link} to="/" />
                      <BottomNavigationAction label="Chromacytos" component={Link} to="/chromacytos" />
                      <BottomNavigationAction label="Mint" component={Link} to="/mint" />
                      <BottomNavigationAction label="About" component={Link} to="/about"  />
                  </BottomNavigation>

                  <Routes>
                    <Route path="/about" element={<About/>}>
                    
                    </Route>
                    <Route path="/mint" element={<MintPage/>}>
                    
                    </Route>
                    <Route path="/chromacytos" element={<ChromacytosInfo/>}>
                    
                    </Route>
                    <Route path="/" element={<Home/>}>
                    
                    </Route>
                </Routes>
                </BrowserRouter>
              </>
                                    
            ) : (
                <>
                </>
          )}
*/
