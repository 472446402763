import React from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    useTheme,
    Box,
    Grid,
    Paper, 
    Avatar,
    Stack,
    useMediaQuery,
    List,
    ListItem,
    ListItemText, 
    Link,
    Divider
  } from "@mui/material";
  import anon_face from '../assets/anon_face.png'

const About = () => {

    const theme = useTheme();
    //console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const isMatchSmall = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box sx={{flexGrow: 1, backgroundColor: "#F8F8FF"}}>
            <Stack 
              direction='column'
              justifyContent= 'center'
              alignItems='center'
              spacing={2}
              
              >
                <Typography variant='h2' mt={4} mx='auto' sx={{fontFamily: 'CocotteAlternate'}}>
                    ABOUT
                </Typography>
                <Box   
                    mt={4}
                    borderRadius={10}
                    border={8}
                      maxWidth={600}
                      component='img'
                      src={anon_face}
                      sx={{
                        width: '96%',
                                                             
                      }}
                      >
                      </Box>
                <Box
                
                
                  maxWidth={isMatch? 700:800}
                  
                  mt={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '96%',
                  }}>
                    <Typography align='left' sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                    I'm an anonymous digital artist with a pseudonym Nigel The Creator or just Nigel. My journey with digital art began in 2021, a time where I was still searching for an outlet to express myself.
                    Luckily I've found a lot of aspiring artists on Hedera Network which inspired me to start the path of creation. However the process to acquire the necessary skillset was long and hard but also extremely enjoyable
                    and cathartic. The initial barrier to learn the neccessary tools that would allow me to create visually compelling art was high but I learned to enjoy the struggle and over time even embrace it.
                    
                    </Typography>
                    <br></br>
                    <Typography align='left' sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                    The fact is I'm in medical school where we learned about properties and mechanics of life. Therefore it shouldn't be surprising that I often draw inspiration from biology, physics and chemistry (sometimes all at once).
                    However emulation of life is only a subset of my artistic endeavours. More recently I've also discovered a curiousity for metaphysical artwork with an emphasis of exploring the basic properties of the universe.
                    </Typography>
                    <br></br>
                    <Typography align='left' sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem', marginRight: 'auto'}}>
                    If you'd like to get in touch feel free to use the information below to contact me.
                    </Typography>
                    <Divider  sx={{width: '100%', marginTop: 3, borderBottomWidth: 3}}></Divider>
                    <Box
                  
                  mr='auto'
                  mt={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    
                  }}>
                    
                    <Typography variant='h4' align='left' mb={2} mt={2} sx={{fontFamily: 'BodoniModa'}}>
                    CONTACT INFORMATION
                    </Typography>
                    <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                    <b style={{fontFamily: 'BodoniModa'}}>Twitter: </b><Link
                      href='https://twitter.com/nigelthecreator'
                      color='inherit'
                      target="_blank"
                      rel="noopener"
                      sx={{textStyle: 'bold', textWeight: 800}}
                      >@nigelthecreator</Link>
                    </Typography>
                    <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                    <b style={{fontFamily: 'BodoniModa'}}>Email: </b>nigelthecreator.hbar@gmail.com
                    </Typography>
                  </Box>
                  <Divider  sx={{width: '100%', marginTop: 3, borderBottomWidth: 3}}></Divider>
                  </Box>
                  <Box
                
                
                  maxWidth={isMatch? 700:800}
                  
                  mt={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '96%',
                  }}>

                    <Typography variant='h4' align='left' mr='auto' sx={{fontFamily: 'BodoniModa'}}>
                      FAQ
                    </Typography>
                    <Typography mr='auto' mt={3} sx={{fontFamily: 'BodoniModa', fontSize: '1.35rem'}}>
                      What tools are you using when creating art?
                    </Typography>
                    <Typography mr='auto' mt={0.8} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      My two most commonly used tools are Nannou and Blender. <Link
                      href='https://nannou.cc/'
                      color='inherit'
                      target="_blank"
                      rel="noopener"
                      sx={{textStyle: 'bold', textWeight: 800}}
                      >Nannou</Link> is an open source coding framework written and used with Rust. What drove me to use it instead of other frameworks (OpenRNDR, Processing)
                      is the fact that the origin of the coordinate system is in the middle of the canvas which is a must for me personally. I also use Blender with which I still only experiment.
                    </Typography>
                    
                    <Typography mr='auto' mt={3} sx={{fontFamily: 'BodoniModa', fontSize: '1.35rem'}}>
                      Do you know how to draw?
                    </Typography>
                    <Typography mr='auto' mt={0.8} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      I do but I'm not very good at it. Thankfully art is more than just drawing.
                    </Typography>
                    
                    <Typography mr='auto' mt={3} sx={{fontFamily: 'BodoniModa', fontSize: '1.35rem'}}>
                      How do you find time to do all of that stuff? 
                    </Typography>
                    <Typography mr='auto' mt={0.8} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      Compromise and sacrifice. Becoming "good" at something requires a huge time investment and it's no different with the artistic process.
                    </Typography>
                    <Typography mr='auto' mt={3} sx={{fontFamily: 'BodoniModa', fontSize: '1.35rem'}}>
                      I'd like to try this art stuff but don't know where to start. Could you help me with that?
                    </Typography>
                    <Typography mr='auto' mt={0.8} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      Sure thing, you have my contact info above. Let's talk.
                    </Typography>
                    <Typography mr='auto' mt={3} sx={{fontFamily: 'BodoniModa', fontSize: '1.35rem'}}>
                      I'd like to get a physical print from one of your work. Is that possible? 
                    </Typography>
                    <Typography mr='auto' mt={0.8} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      Good question! Should there be interest for that I'll make it happen. My art is high quality and should look amazing as a physical print.
                    </Typography>
                    <Typography mr='auto' mt={3} sx={{fontFamily: 'BodoniModa', fontSize: '1.35rem'}}>
                      Why are you minting on Hedera?
                    </Typography>
                    <Typography mr='auto' mt={0.8} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      There are multiple reasons for this, however if I had to choose one it would be the ease of process when minting. Hedera uses a HTS (Hedera Token Service) which allows for minting both fungible and non-fungible tokens without the use of Smart Contracts. The whole network is also carbon negative and uses reletively little electricity.
                    </Typography>
                    <Typography mr='auto' mt={3} sx={{fontFamily: 'BodoniModa', fontSize: '1.35rem'}}>
                      When are you releasing *insert collection name* and where are you minting them? 
                    </Typography>
                    <Typography mr='auto' mt={0.8} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      Make sure you follow me on Twitter for regular updates. The mints will happen exclusively on this website.
                    </Typography>
                  </Box>
                  
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
            
            </Stack>
        </Box>
    )
}

export default About;