import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TwitterIcon from '@mui/icons-material/Twitter';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton } from "@mui/material";

const mailtoHref = "mailto:nigelthecreator.hbar@gmail.com?subject=SendMail&body=Description";


const Footer = () => {
    return (
      <Paper sx={{marginTop: 'calc(10% + 60px)',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      }} component="footer" square variant="outlined">
        <Container maxWidth="lg">
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: "flex",
              my: 0.2
            }}
          >
              <div>
              
              </div>
          </Box>
  
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: "flex",
              mb: 0.45,
            }}
          >
            <Typography variant="caption" color="initial" sx={{fontSize: '0.8rem'}}>
              © 2022 NigelTheCreator. All rights reserved. <IconButton sx={{ marginLeft: 0.7, "& :hover": { color: '#00acee' }}} onClick={() => window.open('https://twitter.com/nigelthecreator', "_blank")}>
                <TwitterIcon
                sx={{color: '#000000', }} 
                ></TwitterIcon>
              </IconButton> 
              <IconButton 
            href="mailto:nigelthecreator.hbar@gmail.com?subject=SendMail&body=Description"
            sx={{"& :hover": { color: '#000000' }}}
              >
                <MailOutlineIcon
                sx={{color: 'gray'}}
                ></MailOutlineIcon>
              </IconButton>
              
            </Typography>
          </Box>
        </Container>
      </Paper>
    );
  }

  export default Footer;

  // HEX code for twitter logo #00acee