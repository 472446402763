import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    useTheme,
    Box,
    Stack,
    useMediaQuery, 
    Link, Button, TextField, IconButton, DialogTitle,
    Dialog
  } from "@mui/material";
import {Link as Link2} from 'react-router-dom';
import HashconnectService from '../service/hashconnectService';
import CircleIcon from '@mui/icons-material/Circle'
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import thankyou from '../assets/thankyou.gif';
import shiba_inu from '../assets/dog-smile.gif'
import axios from 'axios';
import copy from 'clipboard-copy';


const Img = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
})
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
'& .MuiDialogContent-root': {
  padding: theme.spacing(2),
  
},
'& .MuiDialogActions-root': {
  padding: theme.spacing(1),
  
},
'& .MuiDialogTitle-root': {
  fontFamily: 'ProximaNovaBold',
  backgroundColor: '#F8F8FF',
  fontSize: '1.3rem'
},
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};


var hashConnect: HashconnectService = new HashconnectService()
declare const window: any;

const initiateHashconnect = async () => {

await hashConnect.initHashconnect()
    //console.log("VIbe Check")
  }
initiateHashconnect()

interface MintCollection {
  name: string;
  id: number;
  collectionId: string;
  activated: boolean;
}

const MintService = (props: MintCollection) => {

    const [states, changeState] = useState([hashConnect.state.toString()])
    console.log(states[0])
    const [pairingString, changeString] = useState('Pairing String')
    const [availability, changeAvailability] = useState(true)
    const [open, setOpen] = React.useState(false);

    console.log("Local Data: " + window.localStorage.getItem('pairedWallet'))

    
    
    const activated = props.activated;
    const collectionId = props.collectionId;

    var visibility = props.activated ? "visible" : "hidden";
    var visibilityReverse = props.activated ? "hidden" : "visible";
    //var availability = true;


    const showPairingString = () => {
        //console.log(hashConnect.pairingString)
        changeString(hashConnect.pairingString)
      }
  
      
  
      const connectionListener = () => {
        hashConnect.hashconnect.pairingEvent.once((pairingData) => {
          //console.log(hashConnect.state)
          if (hashConnect.state === 'Connected' || hashConnect.state === 'Paired') {
            changeState([hashConnect.state])
            changeString('Pairing String')
            window.localStorage.setItem("pairedWallet", hashConnect.pairingData?.accountIds[0])
          }
        })
      }
  
      const copyClipboard = () => {
        copy(pairingString)
        connectionListener()
      }  
  
      setTimeout(() => {
        //console.log('WHAT')
        showPairingString()
        
      }, 1500)
  
      hashConnect.hashconnect.connectionStatusChangeEvent.once((state) => {
        const walletCheck = window.localStorage.getItem('pairedWallet')
        console.log("Changing state to..." + state)
        if (walletCheck) {
          changeState([state])
          //connectionStatus = "Connected"
          //checkAssociationStatus()
        } else {
          changeState(['Disconnected'])
          //connectionStatus = "Disconnected"
        }
        if (state === 'Paired') {
          changeString("Pairing String")
          //connectionStatus = "Paired"
          //checkAssociationStatus()
        }
      })
      /*
      hashConnect.hashconnect.acknowledgeMessageEvent.once((acknowledgeData) => {
        //console.log('Pizdarija')
        console.log(+ acknowledgeData)
      })   */
  
      const disconnectWallet = async () => {
        hashConnect.disconnect(hashConnect.topic);
        hashConnect.clearPairings();
        changeState([hashConnect.state])
       //console.log(hashConnect.state)
        window.localStorage.removeItem("pairedWallet")
        changeString('Pairing String')
      }
      
  
      const connectWithBrowserExtension = async () => {
  
        console.log(hashConnect.topic)
        const walletCheck = window.localStorage.getItem('pairedWallet')
        console.log(walletCheck)
     
        if (hashConnect.state === 'Paired') {
          disconnectWallet()
          
        }else if (walletCheck) {
          
          disconnectWallet()
        } else if (hashConnect.state === "Disconnected") {
          await initiateHashconnect()
  
          hashConnect.connectToExtension()
          connectionListener()
        } else {
          hashConnect.connectToExtension()
          connectionListener()
        }
      }
  
  
    
    const getAssociateTransBytes = async (accountId: string | undefined) => {
        if (accountId !== undefined) {
            const transRequest = await axios.get("https://barpunks.com/associateTransaction/" + collectionId + "/" + accountId, {responseType: "arraybuffer"})
            const signTx = Uint8Array.from(Buffer.from(transRequest.data, "binary"))
            const signTx2 = Buffer.from(transRequest.data, "binary")
            //console.log(await signTx)
            //console.log(await signTx2)
            return await signTx
        } else {
            //TODO: Error message
        }
    }
  
    const getSwapTransBytes = async (accountId: string) => {
        if (accountId !== undefined) {
            
            const transRequest = await axios.get("https://barpunks.com/mintRequest1/" + collectionId + "/" + accountId, {responseType: "arraybuffer"})
            //console.log(transRequest.data)
            const signTx = Uint8Array.from(Buffer.from(transRequest.data, "binary"))
            //console.log(signTx)
            if (signTx.length > 5) {
                return await signTx
            } else {
                return null
            }
        } else {
            //TODO: Error message
        }
    }
  
    const associateTransaction = async () => {
        if (hashConnect.state === "Paired" || hashConnect.state === "Connected") {
  
            const accountId = window.localStorage.getItem("pairedWallet")
            console.log(accountId)
            
            if (accountId !== null || accountId !== undefined) {
                let transactionBytes = await getAssociateTransBytes(accountId)
    
                await hashConnect.sendTransaction(transactionBytes!, accountId)
            }
            
        }
    }
  
    
    const mintTransaction = async () => {
      if (hashConnect.state === "Paired" || hashConnect.state === "Connected") {
  
        const accountId = window.localStorage.getItem("pairedWallet")
        console.log(accountId)
        
        if (accountId !== null || accountId !== undefined) {
            let transactionBytes = await getSwapTransBytes(accountId)

            if (transactionBytes == null) {
              changeAvailability(false)

              return;
            }

            await hashConnect.sendTransaction(transactionBytes!, accountId, false, true)
        }
        
      }     
    }

    const mintButtonFunctions = () => {
      handleClickOpen();
      mintTransaction();
    }
    
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
            <Box
                border={4}
                borderColor='black'
                  height={470}
                  maxWidth={850}
                  mt={4}
                  mb={4}
                  borderRadius={3}
                  pb={4}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: '90%',
                    backgroundColor: '#222337',
                    color: 'white',
                  }}>
                    <Typography variant='h4' align='left' mx='auto' my={4} sx={{fontFamily: 'CocotteAlternate'}}>
                      Mint With <Link
                      href='https://www.hashpack.app/'
                      color='inherit'
                      target="_blank"
                      rel="noopener"
                      sx={{textStyle: 'bold', textWeight: 800}}
                      >HashPack</Link>
                    </Typography>
                    
                    <CircleIcon sx={{ 
                      color: states[0] !== 'Disconnected' && (window.localStorage.getItem('pairedWallet') !== null || states[0] === 'Paired') ? 'green' : "red"
                      }} ></CircleIcon>
                    <Button onClick={connectWithBrowserExtension} variant='contained' size='large' sx={{marginTop: 2, marginBottom: 2, borderRadius: 4, fontSize: 18, fontFamily: "ProximaBold", fontWeight: 800, backgroundColor: '#6d1b7b'}}>
                      {states[0] !== 'Disconnected' && (window.localStorage.getItem('pairedWallet') !== null || states[0] === 'Paired') ? 'DISCONNECT': "CONNECT"}
                      </Button>

                    <br></br>
                    
                    {
                      states[0] !== 'Disconnected' && (window.localStorage.getItem('pairedWallet') !== null || states[0] === 'Paired') ? 
                      <>
                      <Box
                      justifyContent='center'
                      alignItems='center'
                      sx={{
                        width: activated ? "100%" : "0%",
                        height: activated ? "100%" : "0%",
                        visibility: {visibility}                                 
                      }}
                      >
                        <Button onClick={associateTransaction} variant='contained' size='large' sx={{marginTop: 2, borderRadius: 4, fontSize: 18, fontFamily: "ProximaBold", fontWeight: 800, backgroundColor: '#6d1b7b'}}>
                          ASSOCIATE TOKEN
                        </Button>
                        <Typography mb={2} mt={0.5} sx={{fontFamily: 'ProximaNovaItalic', fontSize: '0.95rem'}}>
                          (The process of associating tokens is needed only once for a given collection.)
                          </Typography> 
                        <Button onClick={mintButtonFunctions} variant='contained' size='large' sx={{marginTop: 2, marginBottom: 4, borderRadius: 4, fontSize: 18, fontFamily: "ProximaBold", fontWeight: 800, backgroundColor: '#6d1b7b'}}>
                        MINT
                        </Button>
                        <BootstrapDialog
                          
                          onClose={handleClose}
                          aria-labelledby="customized-dialog-title"
                          open={open}
                          
                        >
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} >
                            1. Sign The Transaction on HashPack App &#9989; 
                          </BootstrapDialogTitle>
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            2. Check NFTs Tab On HashPack To See What You've Minted &#9989;
                          </BootstrapDialogTitle>
                          <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                            Almost forgot...thank you for minting &#128591; &#128147;
                          </BootstrapDialogTitle>
                          <Box
                            border={8}
                            borderRadius={4}
                            
                            mx='auto'
                            my={2}
                            maxWidth={500}
                            component='img'
                            src={thankyou}
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                              width: '100%',                                 
                            }}
                          >
                          </Box>
                            
                          
                        </BootstrapDialog>
                      </Box>
                        <Box
                            border={3}
                            borderRadius={3}
                            borderColor={activated ? '#222337' : '#FFD700'}
                           
                            
                            maxWidth={180}
                            component='img'
                            src={shiba_inu}
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                              width: activated ? "0%" : "100%",
                              visibility: {visibilityReverse}                                 
                            }}
                          >
                          </Box>
                          <Box
                            
                            border={3}
                            borderColor={availability ? "#222337" : "#d50000"}
                            borderRadius={4}
                            mt={-3}
                            
                            padding={0.8}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              flexDirection: 'column',
                              alignItems: 'center',
                              textAlign: 'left',
                              width: 180,
                              backgroundColor: '#d50000',
                              color: 'white',
                              visibility: availability ? "hidden" : "visible"
                            }}>
                              <Typography align='center'   sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem', visibility: availability ? "hidden" : "visible"}}>
                                THE MINT HAS CONCLUDED
                            </Typography>
                          </Box>
                      </>
                      : 
                      <></>
                      
                    }
                    {pairingString !== 'Pairing String' && window.localStorage.getItem('pairedWallet') == null ?
                    
                    <>
                      <Typography align='center' sx={{marginBottom: 1, fontFamily: 'ProximaNovaItalic', fontSize: '1.15rem', marginTop: 3, color: '#FFFFFF'}} mx='auto'>
                        Pairing String For Manual / Mobile Connection
                      </Typography>
                        <Stack
                        direction="row"
                        justifyContent= 'center'
                        alignItems='center'
                        spacing={1}
                        mb={4}
                        >
                          <TextField 
                            inputProps={{style: {textAlign: "center", backgroundColor: "white"}}} 
                            
                            defaultValue={pairingString} 
                            id="pairing-string-textfield" 
                            variant="outlined"/>
                          <IconButton onClick={copyClipboard}
                          sx={{ marginLeft: 0.7, "& :hover": { color: '#00acee' }}}
                          >
                            <ContentCopyIcon 
                            sx={{color: '#FFFFFF', }}
                            >
                            </ContentCopyIcon>  
                          </IconButton>
                          
                          
                        </Stack> 
                      </>
                    : 
                    <></>
                    
                  }
                  </Box>
    );

}

export default MintService;

/*
const checkAssociationStatus = async () => {
        const accountId = window.localStorage.getItem('pairedWallet')
        //console.log(accountId)
        if (accountId == null || accountId === undefined) {
          return false
        }
        try {
          const response = await axios.get("https://mainnet-public.mirrornode.hedera.com/api/v1/tokens/0.0.882513/balances?account.id=" + accountId, {responseType: 'json'})
          const balances = response.data.balances
          //console.log(balances)
          if (balances.length > 0) {
            return true
          } else {return false}
        } catch (error) {
          return false
        }
        
      }  
      //checkAssociationStatus()
*/