import React, { useState } from 'react';
import {
    AppBar,
    Button,
    Tab,
    Tabs,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
    Box,
    IconButton,
    BottomNavigation,
    BottomNavigationAction,
    Grid
  } from "@mui/material";
import DrawerComp from './Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import {
    BrowserRouter as Router,
    Routes, Route, Link
  } from "react-router-dom";
import { useLocation} from "react-router-dom"
import Home from "../views/Home";
import ChromacytosInfo from "../views/ChromacytosInfo";
import MintPage from "../views/MintPage";
import MyceliumMintPage from '../views/MyceliumMintPage';
import About from "../views/About";
import TermsAndConditions from './TermsAndConditions';
import Launchpad from './Launchpad';

var pathname = window.location.pathname;
console.log(pathname)
const getInitialValue = () => {
    if (pathname === "/") {
        return 0
    } else if (pathname === '/chromacells') {
        return 1
    } else if (pathname === '/about') {
        return 2
    } else { return 3}
}


const Header = () => {
    const [value, setValue] = useState(getInitialValue());
    
    const redirectTag = () => {
        setValue(getInitialValue())
    }
    
    const theme = useTheme();
    //console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const isMatchSmall = useMediaQuery(theme.breakpoints.down("xs"))
    
    //console.log(isMatch);

    return (
        <React.Fragment>
            <Router>
                <Box sx={{ flexGrow: 1, justifyContent: 'center', alignItems: 'center'
            }}>
                    <AppBar position="static"  sx={{background: "#000000", justifyContent: 'space-between'}}>
                        <Toolbar
                        
                        sx={{
                            justifyContent: 'center',
                            alignItems: 'center'
                            
                          }}
                        >
                            <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            mt={1}
                            mb={2}
                            >
                                
                                <Grid item  lg='auto' mx={isMatch?0:28}>
                                <Typography align='center' variant="h4" component={Link} to='/' onClick={redirectTag} sx={{  fontFamily: "Milonga", fontSize: "2rem", textAlign: "center", justifyContent: "center", flexGrow: 1, alignItems: "center", textDecoration: 'none', color: 'white'}}>
                                    Nigel {"{ The Creator }"}
                                </Typography>
                                </Grid>
                                {isMatch ? (
                                        
                                        <DrawerComp />
                                        
                                    ) : (
                                        <Grid item  lg='auto' mx={isMatch?0:28}>
                                        <Tabs
                                            sx={{ justifyContent: "right", textAlign: "center", flexGrow: 1  }}
                                            indicatorColor="secondary"
                                            textColor="inherit"
                                            value={value}
                                            onChange={(e, value) => setValue(value)}
                                            
                                        >
                                            <Tab label="Home" component={Link} to='/'  sx={{fontFamily: "Garamond", fontSize: "1.2rem", fontStyle: "bold", fontWeight: 800}} />
                                            <Tab label="Chromacells" component={Link}  to='/chromacells' sx={{fontFamily: "Garamond", fontSize: "1.2rem", fontStyle: "bold", fontWeight: 800, color: "FFFFFF"}} />
                                            <Tab label="Launchpad" component={Link} to='/launchpad'  sx={{fontFamily: "Garamond", fontSize: "1.2rem", fontStyle: "bold", fontWeight: 800}} />
                                            <Tab label="About" component={Link} to='/about'  sx={{fontFamily: "Garamond", fontSize: "1.2rem", fontStyle: "bold", fontWeight: 800}} />
                                        </Tabs>
                                        </Grid>
                                    )}
                                
                             </Grid>
                        </Toolbar>
                        
                        
                    </AppBar>
                    {isMatch ? (
                            <>
                                <BottomNavigation
                                    
                                    showLabels
                                    value={value}
                                    onChange={(event, newValue) => {
                                    setValue(newValue);
                                        }}
                                                            
                                    sx={
                                        { 
                                            
                                            "& .MuiBottomNavigationAction-root": {
                                        color: "#000000",
                                        fontFamily: "Garamond",
                                        fontSize: "2.3rem",
                                        fontStyle: 'bold',
                                        fontWeight: 800,
                                        display: { xs: 'block', sm: 'block' },
                                        
                                        },
                                        }}
                                    >
                                        <BottomNavigationAction label="Home" component={Link} to="/" />
                                        <BottomNavigationAction label="Chromacells" component={Link} to="/chromacells"  />
                                        <BottomNavigationAction label="Launchpad" component={Link} to="/launchpad" />
                                        <BottomNavigationAction label="About" component={Link} to="/about" />
                                </BottomNavigation>
                            </>
                        ) : (
                            <></>
                        )}
                    
                </Box>
                
                <Routes>
                    <Route path="/launchpad/mycelium" element={<MyceliumMintPage/>}>
                    
                    </Route>
                    <Route path="/launchpad/chromacells" element={<MintPage/>}>
                    
                    </Route>
                    <Route path="/termsandconditions" element={<TermsAndConditions/>}>

                    </Route>
                    <Route path="/about" element={<About/>}>
                    
                    </Route>
                    <Route path="/launchpad" element={<Launchpad/>}>
                    
                    </Route>
                    <Route path="/chromacells" element={<ChromacytosInfo/>}>
                    
                    </Route>
                    <Route path="/" element={<Home/>}>
                    
                    </Route>
                </Routes>
            </Router>
        </React.Fragment>
    )
}

export default Header;

/*

"& .MuiBottomNavigationAction-root": {
                                        color: "#000000",
                                        fontFamily: "Garamond",
                                        fontSize: "1.3rem",
                                        fontStyle: 'bold',
                                        fontWeight: 800,
                                        
                                        },
*/

/*
{isMatch ? (
                            <>
                                <BottomNavigation
                                
                                    showLabels
                                    value={value}
                                    onChange={(event, newValue) => {
                                    setValue(newValue);
                                        }}
                                                            
                                    sx={{ "& .MuiBottomNavigationAction-root": {
                                        color: "#000000",
                                        fontFamily: "Garamond",
                                        fontSize: "2.1rem",
                                        fontStyle: 'bold',
                                        fontWeight: 800,
                                        
                                        },
                                        width: "100%",
                                        position: 'absolute',
                                        bottom: 0
                                        
                                        
                                        }}
                                    >
                                        <BottomNavigationAction label="Home" component={Link} to="/" />
                                        <BottomNavigationAction label="Chromacytos" component={Link} to="/chromacytos"  />
                                        <BottomNavigationAction label="Mint" component={Link} to="/mint" />
                                        <BottomNavigationAction label="About" component={Link} to="/about" />
                                </BottomNavigation>
                            </>
                        ) : (
                            <></>
                        )}

*/
