import React from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    useTheme,
    Box,
    Grid,
    Paper, 
    Avatar,
    Stack,
    useMediaQuery,
    List,
    ListItem,
    ListItemText, 
    Link
  } from "@mui/material";
import algorithm_1 from '../assets/algorithm_1.png';
import algorithm_2 from '../assets/algorithm_2.png';
import sample_1 from '../assets/sample_1.png';
import sample_2 from '../assets/sample_2.png';
import sample_3 from '../assets/sample_3.png';
import sample_4 from '../assets/sample_4.png';
import sample_5 from '../assets/sample_5.png';
import sample_6 from '../assets/sample_6.png';
import sample_7 from '../assets/sample_7.png';
import sample_8 from '../assets/sample_8.png';
import sample_9 from '../assets/sample_9.png';
import sample_10 from '../assets/sample_10.png';
import sample_11 from '../assets/sample_11.png';
import sample_12 from '../assets/sample_12.png';
import sample_13 from '../assets/sample_13.png';
import sample_14 from '../assets/sample_14.png';
import sample_15 from '../assets/sample_15.png';
import sample_16 from '../assets/sample_16.png';
import sample_17 from '../assets/sample_17.png';
import sample_18 from '../assets/sample_18.png';
import sample_19 from '../assets/sample_19.png';
import sample_20 from '../assets/sample_20.png';





const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary,
  }));

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 400,
    color: theme.palette.text.primary,
  }));

const Img = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
});



const Chromacytos = () => {

    const theme = useTheme();
    //console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const isMatchSmall = useMediaQuery(theme.breakpoints.down("sm"));

    return (
          <Box sx={{ flexGrow: 1, backgroundColor: "#F8F8FF"}}>
            <Stack 
              direction='column'
              justifyContent= 'center'
              alignItems='center'
              spacing={2}
              >
                <Box
                
                display='flex'
                mx='auto'
                pr={isMatch?42:102}
                pl={2}
                pt={4}
                sx={{ alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}
                >
                  <Typography variant={isMatchSmall?'h3':'h2'}  align='left' sx={{fontFamily: 'CocotteAlternate'}} >
                    Chromacells
                  </Typography>
                </Box>
                

                <Stack 
                direction={isMatch?"column":"row"}
                justifyContent= 'center'
                alignItems='center'
                spacing={3}
                >
                  <Box
                  
                  maxWidth={isMatch? 600:700}
                  mx={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    
                  }}>
                    <br></br>

                    <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                      <b style={{fontFamily: 'ProximaNovaItalic'}}>Chromacells</b> is a programmatically generated collection made with an algorithm that allows for extremely unique results. The algorithm itself is relatively complex and has quite a few parameters to tune, giving it the ability to create one of a kind NFTs. The pattern used is an <b style={{fontFamily: 'ProximaNovaItalic'}}>isoline</b>, essentially a curve along which the function has a constant value. Isolines (also known as <b style={{fontFamily: 'ProximaNovaItalic'}}>contour lines</b>) are most commonly used in cartography and physics where they’re used to plot electric and magnetic fields. However this generative work is direct proof that they can also be used for emulating something entirely new.
                      </Typography>
                    <br></br>
                    <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                      As a medical student with a lifelong interest in biology and biochemistry it’s quite expected that cellular patterns are often displayed in my mind. After all, cells are the unit of life itself and in our daily routines and workloads we often forget the immense complexity of our micro machinery. And for this reason I’ve created something special to serve as a daily reminder of how amazing our cells are. 
                      </Typography>
                    <br></br>
                    <Box
                      alignSelf='flex-start'
                      sx={{ alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'flex-start', textAlign: 'left'}}
                      >
                        <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                        But enough philosophising, let me explain how the artwork was created.    
                          </Typography>
                      </Box>
                  </Box>
                  <Box
                    maxWidth={isMatch? 620:350}
                    mx={isMatch?2:0}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',                                              
                        }}
                    >

                      <Img src={sample_11} style={{
                        width: '96%', 
                      }} />
                    </Box>
                
                </Stack>
              
                

              </Stack>

              <Stack
              direction='column'
              justifyContent= 'center'
              alignItems='center'
              spacing={1}
              
              >
               
                <Box 
                  maxWidth={isMatch? 600:1080}
                  mx={2}
                  
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '96%',
                  }}>
                    
                    <Typography variant={isMatchSmall?'h3':'h2'}  align='left' mr='auto' mt={4} sx={{fontFamily: 'CocotteAlternate'}}>
                      Algorithm
                    </Typography>
                    <br></br>
                    <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                    The core algorithm for displaying contour lines is an implementation of <b style={{fontFamily: 'ProximaNovaItalic'}}>meandering triangles</b> algorithm
                     which in simple terms allows for drawing isolines without overlap. As per name, this is achieved by using triangles that are not displayed on the final work but are extremely important for creating the isoline pattern.
                    </Typography>
                    <br></br>
                    <Typography align='left' mr='auto' sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                      The core of the Chromacells algorithm was inspired by Bruce Hill's  <Link
                      href='https://blog.bruce-hill.com/meandering-triangles'
                      color='inherit'
                      target="_blank"
                      rel="noopener"
                      sx={{textStyle: 'bold', textWeight: 800}}
                      >
                       Meandering Triangles Algorithm</Link>. 
                    </Typography>

                    <Box
                      
                      maxWidth={800}
                      component='img'
                      src={algorithm_1}
                      
                      sx={{
                        display: 'flex',
                        marginRight: 'auto',
                        marginTop: 2,
                        width: '100%',
                        flexDirection: 'column',
                        
                                                                   
                        }}
                      >
                    
                      </Box>
                      <Box 

                        mx={2}
                        
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          textAlign: 'left',
                          width: '100%',
                        }}>
                          <Typography align='left' mx={isMatch?'auto':34} sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}}>
                            The output of the base function
                          </Typography>
                          <Typography align='left' sx={{marginTop: 4, fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                          To summarise what’s happening under the hood:
                          </Typography>
                          <List >
                            <ListItem>
                              <ListItemText primary={
                                <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.15rem'}}>1. Create random points on the grid</Typography>
                              } />
                            </ListItem>
                            <ListItem>
                              <ListItemText primary={
                                <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.15rem'}}>2. Assign them to two-dimensional triangles</Typography>
                              } />
                            </ListItem>
                            <ListItem>
                              <ListItemText primary={
                                <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.15rem'}}>3. Use the triangle grid to create a pyramid grid which allows us to assign gradients.</Typography>
                              } />
                            </ListItem>
                            <ListItem>
                              <ListItemText primary={
                                <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.15rem'}}>4. Create the gradient with a noise function and randomly scale the points for additional diversity in results</Typography>
                              } />
                            </ListItem>
                            <ListItem>
                              <ListItemText primary={
                                <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.15rem'}}>5. Use the data to draw isolines and randomly assign them colour from a randomly generated colour profile (not 1 result has the same colour patterns)</Typography>
                              } />
                            </ListItem>
                            
                          </List>
                          <Box
                            
                            maxWidth={550}
                            component='img'
                            src={algorithm_2}
                            
                            sx={{
                              width: '100%',
                              marginRight: 'auto',
                              marginTop: 2,                                       
                              }}
                            >
                            </Box>
                          <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}}>
                            Well this one looks interesting
                            </Typography>
                          <Typography align='left' sx={{marginTop: 4, fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                            The algorithm for assigning gradient is called ridged-multifractal noise, a type of noise that is often used for generating terrain in 3D modelling and gaming.
                            </Typography>
                          <Box
                            
                            maxWidth={900}
                            component='img'
                            src={sample_2}
                            
                            sx={{
                              width: '100%',
                              marginRight: 'auto',
                              marginTop: 2,                                       
                              }}
                            >
                            </Box>
                            <Typography align='left' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}}>
                            A microscopic look of Superman's cells with absorbed solar radiation
                            </Typography>
                            <Typography align='left' sx={{marginTop: 4, fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                            The noise generator also has parameters that can we can manipulate with.
                             These parameters are octaves, frequency, lacunarity, persistence and attenuation.
                              Each Chromacell has the aforementioned parameters embedded in the NFT attributes.
                               To learn more about how they influence the final result I recommend checking out the <Link
                               href='https://docs.rs/noise/0.7.0/noise/struct.RidgedMulti.html'
                               color='inherit'
                               target="_blank"
                               rel="noopener"
                               sx={{textStyle: 'bold', textWeight: 800}}
                               >
                               official documentation.</Link>
                            </Typography>
                            <br />
                            
                            
                      </Box>
                      
                     
                    
                    
                    

                  </Box>


                  <Box 
                  maxWidth={isMatch? 600:1080}
                  mx={2}
                  
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '96%',
                  }}>
                    
                    <Typography variant={isMatchSmall?'h3':'h2'}  align='left' mr='auto' mt={4} sx={{fontFamily: 'CocotteAlternate'}}>
                      Showcase
                    </Typography>
                    <br></br>
                    <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                      One of the most interesting aspects of this algorithm is the variety of shapes it can produce, which gives us the freedom to interpret the artwork
                      based on the pattern that our brains recognize. This is in accordance with the notion that we tend to comprehend a given image with things we've been most exposed to during our lifetime.
                      For example, I myself see cells working hard in their endless fight with entropy which makes perfect sense given my educational background. However someone else might
                      see the colourful representation of meteorological report, in-game terrain map, cosmic background radiation etc..
                    </Typography>
                    <br></br>
                    <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                      I promise that this is the part where I get to the point of what I'm trying to say. You as the owner or observer of this work have the right to interpret it as you like. The collection name Chromacells
                      is not meant to be the barrier of your own imagination. I would love to read your own personal thoughts and emotions induced by Chromacells so feel free to share them on social media platforms.
                    </Typography>
                    <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_3}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}}>
                    To me this looks like necrosis that's devouring the tissue due to some unknown pathogen. Yikes!
                      </Typography>
                    <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_4}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        These cells have been infected with the Zombie virus. And yes, their diet mainly consists of fresh non-infected brains.
                      </Typography>

                    <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_5}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Finally some healthy looking tissue. A bit of stroma with an adequate blood supply.
                      </Typography>
                    
                    <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_6}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Another necrosis or fibroblasts producing collagen. Who knows...
                      </Typography>
                    
                    <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_7}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        We've increased the zoom to intracellular structures. I can clearly see floating vacuoles and filaments that provide structural integrity.
                      </Typography>

                    <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_8}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}}>
                        Oh yeah this one is common in children. Cartilage turning into bone.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_9}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}}>
                        Osteogenesis and Osteolysis. Perfectly balanced.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_10}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Capillary network with slowly-moving red blood cells.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_15}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        This one is tricky! These could be neurons and their numerous synapses.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_12}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        There's a common misconception that there's only 1 mitochondria in each cell. There's much more than that since cells require a lot of power which they are more than happy to provide.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_13}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Fat tissue. Easy to gain but hard to lose.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_14}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Fat in children. Perfectly normal and used for heating purposes.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_16}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Large blood vessels in brain tissue. 
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_17}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Lymph nodes are crucial when it comes to acquired immunity. Amongst other things they make sure immune cells correctly mature. Here's a bunch of them.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_18}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Are these...neurons? 
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_19}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Neutrophils attacking the intrudors. Lethal and always ready to battle.
                      </Typography>

                      <Box   
                      maxWidth={900}
                      component='img'
                      src={sample_20}
                      sx={{
                        width: '100%',
                        marginRight: 'auto',
                        marginTop: 2,                                       
                      }}
                      >
                      </Box>
                    <Typography align='left' mr='auto' sx={{marginBottom: 3, fontFamily: 'Lato', fontSize: '1.15rem', marginTop: 1, color: '#2F4F4F'}} >
                        Heart muscle cells. A machine that makes multicellular life possible.
                      </Typography>
                    <br></br>
                    <br></br>
                    <br></br>

                    
                      
                     
                    
                    
                    

                  </Box>
                  
                  
                
              </Stack>
              
            </Box>
    )
}

export default Chromacytos;

// <Img src={sample_1} sx={{maxHeight: '40%', minHeight: '40%', maxWidth: '40%', minWidth: '40%'}}/>

/*
<Item>
                  
                <br></br>
                  <Typography align='left'>
                  Chromacytos is a programmatically generated collection made with an algorithm that allows for extremely unique results. The algorithm itself is relatively complex and has quite a few parameters to tune which is perfect for creating diverse and interesting results. The pattern used is an isoline, essentially a curve along which the function has constant value. Isolines (also known as contour lines) are most commonly used in cartography and also physics where they’re used to plot electric or magnetic fields. However this generative work is direct proof that they can also be used for emulating something different.



                  </Typography>
                  <br></br>

                  <Typography align='left'>
                  As a medical student with a lifelong interest in biology and biochemistry it’s quite expected that the picture of cellular patterns are often displayed in my mind. After all, cells are the unit of life itself and in our daily routine and workload we often forget the immense complexity of our micro machinery. And for this reason I’ve created something special to serve as a daily reminder of how awesome our cells are. 

                  </Typography>
                  <br></br>

                  <Typography align='left'>
                  But enough philosophising, let me explain the creation process.

                  </Typography>
                  </Item>



                    <Typography >
  
                    </Typography>
                    <br></br>
  
                    <Typography >
  
                    </Typography>


*/

/*
<Box sx={{ flexGrow: 1}}>
            <Grid 
              container
              spacing={2}
              direction="row"
              justifyContent=""
              alignItems="center"
            >
            <Grid item xs={12} md={12} lg={12}>
            <Box
              maxWidth={500}
                  sx={{
                    mx: 'auto',
                    borderColor: '#ffffff',
                    borderRadius: 16,
                    display: "flex",
                    flexDirection: "column", 
                    alignItems: "center",
                    justifyContent: "center",
                    
                    
                }}>
                <Typography  sx={{fontSize: '2.1rem'}} align='center'>
                  Chromacytos
                </Typography>
                </Box>
                </Grid>
              

                <Grid item lg={6} sx={{border: 1, justifyContent: 'right', alignItems: 'right', textAlign: 'center'}}>
              <Box 
              maxWidth={500}
              sx={{
                
                display: 'flex',
                justifyContent: 'right',
                flexDirection: 'column',
                alignItems: 'right',
                textAlign: 'center',
                border: 1,
                
                
                }}>
              
                    
                  <br></br>
                    <Typography >
                    Chromacytos is a programmatically generated collection made with an algorithm that allows for extremely unique results. The algorithm itself is relatively complex and has quite a few parameters to tune which is perfect for creating diverse and interesting results. The pattern used is an isoline, essentially a curve along which the function has constant value. Isolines (also known as contour lines) are most commonly used in cartography and also physics where they’re used to plot electric or magnetic fields. However this generative work is direct proof that they can also be used for emulating something different.



                    </Typography>
                      <br></br>
                      <Typography>
                      As a medical student with a lifelong interest in biology and biochemistry it’s quite expected that the picture of cellular patterns are often displayed in my mind. After all, cells are the unit of life itself and in our daily routine and workload we often forget the immense complexity of our micro machinery. And for this reason I’ve created something special to serve as a daily reminder of how awesome our cells are. 

                      </Typography>
                  <br></br>
                  <Typography>
                  But enough philosophising, let me explain the creation process.

                  </Typography>
                  
              </Box>
              </Grid>
              <Grid item lg sx={{border: 1}}>
              <Box
                  maxWidth={300}
                  sx={{
                    
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    
                    
                    
                    }}
                  >

                  <Img src={sample_1} sx={{ }}/>
                  </Box>
                  </Grid>
                </Grid>
            </Box>



            <Box
              maxWidth={500}
                  sx={{
                    mx: 'auto',
                    borderColor: '#ffffff',
                    borderRadius: 16,
                    display: "flex",
                    flexDirection: "column", 
                    alignItems: "center",
                    justifyContent: "center",
                    
                    
                }}>
                <Typography  sx={{fontSize: '2.1rem'}} align='center'>
                  Chromacytos
                </Typography>
                </Box>




                <Box
                      border={1}
                      maxWidth={450}
                      component='img'
                      src={algorithm_1}
                      
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginRight: 'auto',
                        marginTop: 5,
                        alignContent: 'flex-end',
                        width: '96%'                                            
                        }}
                      >
                    
                      </Box>


                       <Box
                  display='flex'
                  mx='auto'
                  pr={isMatch?48:106}
                  pl={2}
                  pt={8}
                  sx={{ alignItems: 'center', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}
                  
                  >
                    
                    <br></br>
                    
                  </Box>
*/