import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    useTheme,
    Box,
    Stack,
    useMediaQuery, 
    Link, Button, TextField, IconButton, DialogTitle, Divider,
    Dialog
  } from "@mui/material";
import {Link as Link2} from 'react-router-dom';
import MintPage from '../views/MintPage';
import mycelium_launchpad from '../assets/mycelium_launchpad.png';
import sample_25 from '../assets/sample_25.png';


const Launchpad = () => {

    const theme = useTheme();
    //console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const isMatchSmall = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: "#F8F8FF"}}>
            <Stack 
              direction='column'
              justifyContent= 'center'
              alignItems='center'

              >
                <Stack 
                direction={isMatch?"column":"row"}
                justifyContent= 'center'
                alignItems='center'
                mt={2}
                spacing={3}
                width="96%"
                >
                    <Box
                    component={Link2}
                    to='/launchpad/mycelium'
                    mt={2}
                    mb={2}
                    borderRadius={6}
                    border={5}
                    maxWidth={isMatch ? 600:440}
                    
                    sx={{textDecoration: 'none', color: 'black', backgroundColor: '#bef9f7', width: '98%', '&:hover': {
                        boxShadow: 22
                     }}}
                    >
                    <Typography variant='h3' mt={2} sx={{fontFamily: 'CocotteAlternate'}}>
                        MYCELIUM
                    </Typography>
                    <Typography mb={2} sx={{fontFamily: 'ProximaNovaItalic'}}>
                        NigelTheCreator
                    </Typography>
                    <Box
                    border={1}
                    borderRadius={1.5}
                    maxWidth={500}
                    component='img'
                    src={mycelium_launchpad}
                    sx={{
                      width: '98%',
                                                           
                    }}
                    ></Box>
                    <Typography mt={1} sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.4rem'}}>
                        Mint Date To Be Determined
                    </Typography>
                    <Typography  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.4rem'}}>
                        Total Supply: 1222
                    </Typography>
                   
                    <Box
                    mx='auto'
                    mt={1}
                    mb={2}
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 200,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': {
                        background: "#1a237e",
                     }
                    }}
                    >
                      <Typography align='left'  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.4rem'}}>
                        VIEW PROJECT
                    </Typography>
                    
                    </Box>
                    </Box>
                    <Box
                    component={Link2}
                    to='/launchpad/chromacells'
                    mt={1}
                    mb={2}
                    borderRadius={6}
                    border={5}
                    maxWidth={isMatch ? 600:440}
                    
                    sx={{textDecoration: 'none', color: 'black', backgroundColor: '#cff9cc', width: '98%', '&:hover': {
                        boxShadow: 22
                     }}}
                    >
                    <Typography variant='h3' mt={2} sx={{fontFamily: 'CocotteAlternate'}}>
                        CHROMACELLS
                    </Typography>
                    <Typography mb={2} sx={{fontFamily: 'ProximaNovaItalic'}}>
                        NigelTheCreator
                    </Typography>
                    <Box
                    border={1}
                    borderRadius={1.5}
                    component='img'
                    maxWidth={500}

                    src={sample_25}
                    sx={{
                      width: '98%',
                                                           
                    }}
                    ></Box>
                    <Typography mt={1} sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.4rem', color: 'green'}}>
                        MINT OPEN
                    </Typography>
                    <Typography  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.4rem'}}>
                        Total Supply: 222
                    </Typography>
                   
                    <Box
                    mx='auto'
                    mt={1}
                    mb={2}
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 200,
                      backgroundColor: 'black',
                      color: 'white',
                      '&:hover': {
                        background: "#1a237e",
                        
                     }
                    }}
                    >
                      <Typography align='left'  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.4rem'}}>
                        VIEW PROJECT
                    </Typography>
                    
                    </Box>
                    </Box>
                    
                </Stack>
                <Stack 
                direction={isMatch?"column":"row"}
                justifyContent= 'center'
                alignItems='center'
                mt={2}
                spacing={3}
                width="96%"
                >
                    <Box
                    mt={2}
                    mb={2}
                    borderRadius={6}
                    border={5}
                    maxWidth={isMatch ? 600:540}
                    
                    sx={{textDecoration: 'none', color: 'black', backgroundColor: '#F5F5F5'}}
                    >
                        <Typography variant='h3' mt={2} sx={{fontFamily: 'CocotteAlternate'}}>
                        About My Work
                        </Typography>
                        <Typography mx={4} sx={{fontFamily: 'ProximaNova', fontSize: '1.4rem'}}>
                          They say being a perfectionist can cause chronic stress but that's a sacrifice I'm willing to make in order to ensure every single NFT minted here is high quality.
                        </Typography>
                        <br></br>
                        <Typography mx={4} sx={{fontFamily: 'ProximaNova', fontSize: '1.4rem'}}>
                          Although these images are computer generated they're all also manually checked in order to ensure only the best of them are part of the collection. Furthermore due to the the fact
                          that the images itself are high quality they can also be printed and displayed in your home.
                        </Typography>
                        <br></br>
                        

                        <br></br>
                        
                    </Box>
                </Stack>
               
              </Stack>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

        </Box>
    )

}

export default Launchpad;

/*
<Divider  sx={{width: '100%', marginTop: 0.5, borderBottomWidth: 3, color: 'black'}}></Divider>
                        
                        <Typography align='center' mb={0.5} mt={2} sx={{fontFamily: 'BodoniModa', fontSize: '1.6rem'}}>
                          GUARANTEE OF AUTHENTICITY
                        </Typography>
                        
                        <Typography sx={{fontFamily: 'ProximaNova', fontSize: '1.25rem'}}>
                        The artwork on this launchpad was created by me, NigelTheCreator
                        </Typography>
                        
                        <Divider  sx={{width: '100%', marginTop: 2, borderBottomWidth: 3}}></Divider>

*/