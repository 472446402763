import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Typography,
    useTheme,
    Box,
    Stack,
    useMediaQuery, 
    Link, Button, TextField, IconButton, DialogTitle,
    Dialog
  } from "@mui/material";
import MintService from '../service/MintService';
import {Link as Link2} from 'react-router-dom';
import HashconnectService from '../service/hashconnectService';
import mycelium_sample_1 from '../assets/mycelium_sample_1.png';



var hashConnect: HashconnectService = new HashconnectService()
declare const window: any;


export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const MyceliumMintPage = () => {

    const [open, setOpen] = React.useState(false);

    const theme = useTheme();
    //console.log(theme);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));
    const isMatchSmall = useMediaQuery(theme.breakpoints.down("sm"));

    
    return (
        <Box sx={{ flexGrow: 1, backgroundColor: "#F8F8FF"}}>
            <Stack 
              direction='column'
              justifyContent= 'center'
              alignItems='center'

              >
                <Box
                  maxWidth={900}
                  my={4}
                  
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '96%'
                  }}>
                    <Typography variant='h3' align='left' mx='auto' sx={{fontFamily: 'CocotteAlternate'}}>
                      Mycelium | Emulation of Poetic Disorder
                    </Typography>
                  </Box>
                
                <Stack 
                direction={isMatch?"column":"row"}
                justifyContent= 'center'
                alignItems='center'
                
                spacing={1}
                width="96%"
                >
                  
                    
                <Box
                  
                  maxWidth={580}
                  mt='auto'
                  mb={1}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    width: '100%'
                  }}>
                      <Box
                    
                    maxWidth={580}
                    mb='auto'
                    
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: '100%'
                    }}>
                      <Typography mb={isMatch?2:1} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                        <b style={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>Mycelium 1st Edition</b> is a collection of 1222 computer generated Hyphae, simulating many possible growth patterns of these tiny and fragile structures.
                         The main function of this artwork is the <b style={{fontFamily: 'ProximaNovaItalic'}}>Flow Field</b> algorithm, capable of outputing beautiful asymetrical distortions that are perfect to showcase the mycelium growth.
                        These NFTs are of 5 different sizes, 2 separate noise functions (Normal and Freaky) along with more than 20 different custom made colour palettes.
                        
                      </Typography>
                      <Typography mb={isMatch?2:1} sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                      By acquiring the NFT you get a digital proof of ownership, immutably stored on the Hedera Network. Every NFT minted here is also carbon negative.
                      </Typography>
                      <Typography mb={isMatch?2:3.6} mr='auto' sx={{fontFamily: 'ProximaNova', fontSize: '1.2rem'}}>
                        To make sure you don't miss out on the mint date make sure you follow me on <Link
                      href='https://twitter.com/nigelthecreator'
                      color='inherit'
                      target="_blank"
                      rel="noopener"
                      sx={{textStyle: 'bold', textWeight: 800, fontFamily: 'ProximaNovaBold'}}
                      >Twitter</Link>. WL spots will also be available.
                      </Typography>
                      
                    </Box>

                    
                    <Box
                    mb={1}
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    ml={2}
                    
                    padding={1.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 220,
                      backgroundColor: 'black',
                      color: 'white',

                    }}>
                      <Typography align='left'  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>
                        Date: To Be Determined
                    </Typography>
                    
                    </Box>
                    <Box
                    mb={1}
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    ml={2}
                    
                    padding={1.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 220,
                      backgroundColor: 'black',
                      color: 'white',

                    }}>
                      <Typography align='left'   sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>
                        1st Edition Supply:   1222
                    </Typography>
                    </Box>
                    <Box
                    
                    border={3}
                    borderColor='black'
                    borderRadius={4}
                    ml={2}
                    
                    padding={1.5}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'left',
                      width: 220,
                      backgroundColor: 'black',
                      color: 'white',

                    }}>
                      <Typography align='left'  sx={{fontFamily: 'ProximaNovaBold', fontSize: '1.2rem'}}>
                        Price: To Be Determined 
                    </Typography>
                    </Box>
                    
                  </Box>
                  <Box   
                    mt={0}
                    borderRadius={4}
                    border={6}
                      maxWidth={600}
                      
                      component='img'
                      src={mycelium_sample_1}
                      sx={{
                        width: '96%',
                                                             
                      }}
                      >
                      </Box>

                </Stack>
                <MintService name='Mycelium' id={2} collectionId={"0.0.1391424"} activated={false}></MintService>
                  <Box
                  
                  height={100}
                  mx={2}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'left',
                    
                  }}>
                    <Typography mb='auto' sx={{fontFamily: 'Arial', fontSize: '12px'}}>
                      By Clicking "MINT" Button You Agree With The <Link
                      component={Link2}
                      to='/termsandconditions'
                      color='inherit'
                      target="_blank"
                      
                      >Terms and Conditions</Link>
                    </Typography>
                  </Box>
                  <Button component={Link2} to="/launchpad" variant='contained' size='large' sx={{borderRadius: 4, fontSize: 18, fontFamily: "ProximaBold", fontWeight: 800, textDecoration: 'none', '&:hover': {
                        boxShadow: 5,
                        backgroundColor: '#0d47a1'
                     }}}>
                    BACK TO LAUNCHPAD
                  </Button>
              </Stack>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>

        </Box>
    )
}

export default MyceliumMintPage;

/* 500 ℏ */